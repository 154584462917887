// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction

// Import UJS so we can access the Rails.ajax method
import Rails from "@rails/ujs";
import { Controller } from "stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  connect() {
    var el = this.element
    this.sortable = Sortable.create(el, {
      swapThreshold: 1,
      animation: 250,
      filter: '.pinned',
      onMove: event => {
        return !event.related.classList.contains('pinned')
      },
      onEnd: this.end.bind(this),
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let data = new FormData()
    data.append("position", event.newIndex + 1)
    data.append("scene_id", id)
    Rails.ajax({
      url: this.data.get("url").replace(":id", id),
      type: 'PATCH',
      data: data
    })
  }
}
