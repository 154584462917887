const LiveChat = (shop) => {
  window.__lc = window.__lc || {};
  window.__lc.license = 11930697;
  window.__lc.chat_between_groups = false; // this will create a new chat session between sites vidday, videogreet, helloworld
  ;(function (n, t, c) {
    function i(n) {
      return e._h ? e._h.apply(null, n) : e._q.push(n)
    }
    var e = {
      _q: [],
      _h: null,
      _v: '2.0',
      on: function () {
        i(['on', c.call(arguments)])
      },
      once: function () {
        i(['once', c.call(arguments)])
      },
      off: function () {
        i(['off', c.call(arguments)])
      },
      get: function () {
        if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.")
        return i(['get', c.call(arguments)])
      },
      call: function () {
        i(['call', c.call(arguments)])
      },
      init: function () {
        var n = t.createElement('script')
        ;(n.async = !0),
          (n.type = 'text/javascript'),
          (n.src = 'https://cdn.livechatinc.com/tracking.js'),
          t.head.appendChild(n)
      },
    }
    !n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e)
  })(window, document, [].slice);

  const initLiveChat = () => {
    function checkWidget() {
      if(window.LC_API?.is_loaded() === false) {
        window.setTimeout(checkWidget, 1000);
      } else {
        LiveChatWidget.on("ready",()=>{
          $('#chat-widget-container').data('turbolinks','permanent');
          var data = $('#chat-data').data();
          var options={ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour:'numeric'}
          var variables = {
            "client": data?.client,
            "approved": data?.video.approved_at ? `Approved ${new Date(data?.video.approved_at).toLocaleDateString(undefined,options)}` : '-',
            "review_flag": data?.video.review_flag_at ? `Flagged ${new Date(data?.video.review_flag_at).toLocaleDateString(undefined,options)}` : '-',
            "denied": data?.video.denied_at ? `Denied ${new Date(data?.video.denied_at).toLocaleDateString(undefined,options)}` : '-',
            "deny_reason": data?.denyReason,
            "auditorium_number": data?.video.auditorium_number,
            "booking_ref": data?.video.booking_ref,
            "cinema_name": data?.video.cinema_name,
            "contact_email": data?.video.contact_email,
            "contact_name": data?.video.contact_name,
            "film_title": data?.video.film_title,
            "final_video_url": data?.video.final_video_url,
            "number_slots": data?.video.number_slots,
            "render_error": data?.video.render_error,
            "rendering": data?.video.rendering,
            "renders_count": data?.video.renders_count,
            "showtime_datetime": data?.video.showtime_datetime ? new Date(data?.video.showtime_datetime).toLocaleDateString(undefined,options) : 'N/A'
          }
          LiveChatWidget.call('update_session_variables', variables);          
        });
      }
    }
    checkWidget();
	};
  
  initLiveChat();

};

export { LiveChat };