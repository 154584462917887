// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "jquery";
import $ from "expose-loader?exposes=$,jQuery!jquery"
import 'bootstrap';

import '../js/bootstrap_js_files.js';
import { fileUpload } from '../js/file_upload';
import { LiveChat } from '../js/live_chat';

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

Rails.start();
ActiveStorage.start();
Turbolinks.start();

LiveChat();

// Use 'DOMContentLoaded' event if not using Turbolinks
document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('#uppy-widget-form input[type=file]').forEach(fileInput => {
    fileUpload(fileInput);
  });
});

import "controllers"
