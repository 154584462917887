// app/frontend/fileUpload.js
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import Informer from '@uppy/informer'
import AwsS3 from '@uppy/aws-s3'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import Webcam from '@uppy/webcam'
import Audio from '@uppy/audio'
import Facebook from '@uppy/facebook'
import Instagram from '@uppy/instagram'
import GoogleDrive from '@uppy/google-drive'
import Dropbox from '@uppy/dropbox'
import Url from '@uppy/url'
import { getOperatingSystem } from './utils/getUserOS';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';

function fileUpload(fileInput) {
  const hiddenInput = document.querySelector('.upload-data'),
        formGroup = fileInput.parentNode,
        accept = fileInput.accept;
  // const clientId = hiddenInput.dataset.clientId;
  // remove our file input in favour of Uppy's
  formGroup.removeChild(fileInput);

  const os = getOperatingSystem();
  // this doesnt work anymore :/ .. .just going to force webcam plugin and force picture only mode when uploading picture...
  const plugins = (os == 'Desktop/Laptop' && accept.includes('video')) ? ['Webcam'] : os == 'Desktop/Laptop' ? ['Url'] : [];
  // const plugins = ['Webcam'];
  const screenMessage = (os == 'Desktop/Laptop')
      ? 'Drag and drop files here or choose one of the following options to record or upload your message or photos:'
      : '%{browse}';

  const uppy = new Uppy({
    autoProceed: true,
    restrictions: {
      allowedFileTypes: accept.split(',')
    },
    // onBeforeUpload: (files) => {
    //   const updatedFiles = Object.assign({}, files);    
    //   Object.keys(updatedFiles).forEach((fileId) => {
    //     const dateString = Date.now();
  
    //     updatedFiles[fileId].name = `client_${clientId}/video_4/${dateString}.${updatedFiles[fileId].extension.replace('&ct=g', '')}`;
    //     updatedFiles[fileId].extension = `${updatedFiles[fileId].extension.replace('&ct=g', '')}`;
    //     console.log("before-upload",updatedFiles[fileId]);
    //   });
    //   return updatedFiles
    // }
  })
  // /**
  // * URL path
  // */
  .use(Url, { companionUrl: '/' })
  /**
  * Webcam
  */
  .use(Dashboard, {
    // plugins: plugins,
    inline: true,
    target: formGroup,
    showProgressDetails: true,
    proudlyDisplayPoweredByUppy: false,
    browserBackButtonClose: false,
    doneButtonHandler: null,
    locale: {
      strings: {
        myDevice: os == 'Desktop/Laptop' ? `Upload` : `CAMERA OR LIBRARY`,
        done: 'Cancel',
        // Used as the label for the link that opens the system file selection dialog.
        browse: os == 'Desktop/Laptop' ? 'SELECT FROM COMPUTER' : 'CAMERA OR LIBRARY',
        // Text to show on the droppable area.
        dropPasteFiles: screenMessage,
        dropPasteFolders: screenMessage,
        dropPasteBoth: screenMessage,
        dropPasteImportFiles: screenMessage,
        dropPasteImportFolders: screenMessage,
        dropPasteImportBoth: screenMessage,
        dropHint: screenMessage,
        dropHereOr: screenMessage,
        dropPaste: screenMessage,
        dropPasteImport: screenMessage,
      },
    },
  })
  .use(Informer, {
    target: formGroup,
  })

  if (fileInput.dataset.uploadServer == 's3') {
    uppy.use(AwsS3, {
      companionUrl: '/', // will call Shrine's presign endpoint mounted on `/s3/params`
    });
  } else if (fileInput.dataset.uploadServer == 's3_multipart') {
    uppy.use(AwsS3Multipart, {
      companionUrl: '/' // will call uppy-s3_multipart endpoint mounted on `/s3/multipart`
    });
  }

  if (os == 'Desktop/Laptop' && accept.includes('video')) {
    uppy  // Use Webcam for desktop  
    .use(Webcam, { 
      modes: accept.includes('video') 
        ? [ 'video-audio'] 
        : [ 'picture'],
      mirror: true,
      videoConstraints: {
        facingMode: "user",
        width: { min: 720, ideal: 1280, max: 1920 },
        height: { min: 480, ideal: 800, max: 1080 },
      },
      metaFields: [
        { id: 'name', name: 'Name', placeholder: 'file name' },
        { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
      ],
      showRecordingLength: true,
      showVideoSourceDropdown: true,
      preferredVideoMimeType: 'video/mp4',
      title: 'Record',
    })
  }
  
  uppy.on('upload-success', (file, response) => {

    // console.log("UPPY upload-success Response", response);
    // console.log("UPPY upload-success File", file);

    let id;
    if (fileInput.dataset.uploadServer == 's3') {
      id = file.meta['key'].match(/^upload\/(.+)/)[1]; // object key without prefix
    } else if (fileInput.dataset.uploadServer == 's3_multipart') {
      id = response.uploadURL.match(/\/upload\/([^\?]+)/)[1]; // object key without prefix
      // response.uploadURL.match(`${clientId}/upload\/([^\?]+)`)[0]
    }
    
    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      id, // object key without prefix
      storage: 'upload',
      uploadURL: response.uploadURL,
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
        medium: file.source,
        source_ext: `.${file.extension.replace('&ct=g', '')}`
      }
    };
    
    hiddenInput.value = JSON.stringify(uploadedFileData);

    document.getElementById('uppy-widget-form').submit();

  })

  formGroup.onsubmit = () => uppy.info('Uploaded the video successfully');

}

export { fileUpload };
